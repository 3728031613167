import { AdminTagList } from '../components/Tagging';

function TagsRoute() {
  return <AdminTagList />;
}

export const Component = TagsRoute;

export function clientLoader() {
  return null;
}
